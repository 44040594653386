<template>
  <div id="forgetPassword">
    <h1 class="title tc">超管登录界面</h1>
    <div class="loginBox">
      <div class="backLogin" @click="backLogin">
        <img class="arrowLeft" src="~@/assets/images/goback.png" />
        <span>返回登录</span>
      </div>
      <input class="telephone" v-model="telephone" placeholder="输入注册时的手机号" />
      <div class="codeFrame">
        <input class="code" v-model="captcha" placeholder="请输入验证码" />
        <el-button type="text" @click="getCode" v-show="is_getCode==0">获取验证码</el-button>
        <el-button type="text" v-show="is_getCode!=0" :disabled="true">{{captchaNum}}s后重新获取</el-button>
      </div>
      <input
        class="resetCode"
        type="password"
        v-model="resetPassword"
        placeholder="重置密码"
        min="6"
        max="20"
      />
      <el-button class="loginBtn tc" @click="next">确定</el-button>
    </div>
  </div>
</template>


<script type="text/ecmascript-6">
import * as validata from "@/utils/validata";
import { error, success } from "@/utils/notification";
export default {
  data() {
    return {
      telephone: "",
      captcha: "",
      resetPassword: "",

      // 是否点击过获取验证码标识
      is_getCode: 0,

      // 获取验证码的倒计时
      captchaNum: 60
    };
  },
  methods: {
    // backLogin
    backLogin() {
      this.$router.push("/superLogin");
    },

    // 获取验证码
    getCode() {
      if (!validata.checkPhoneNumber(this.telephone)) {
        error("请正确输入手机号码");
      } else {
        this.$superHttp
          .post(this.URL.superUrl.sendSms, {
            mobile: this.telephone
          })
          .then(res => {
            if (res.data.code === 200) {
              this.is_getCode = 1;
              var timer = setInterval(() => {
                this.captchaNum--;
              }, 1000);
              setTimeout(() => {
                clearInterval(timer);
                this.captchaNum = 60;
                this.is_getCode = 0;
              }, 60000);
            } else {
              // error(res.data.error_msg);
            }
          });
      }
    },

    // 登录
    async next() {
      if (!validata.checkPhoneNumber(this.telephone)) {
        error("请正确输入手机号码");
      } else if (validata.getNull(this.captcha)) {
        error("请正确输入验证码");
      } else if (validata.getNull(this.resetPassword)) {
        error("请正确输入重置密码");
      } else if (validata.checkPassword(this.resetPassword)) {
        error("重置密码长度应为6-20");
      } else {
        const res = this.$superHttp.post(this.URL.superUrl.resetPassword, {
          mobile: parseInt(this.telephone),
          code: parseInt(this.captcha),
          password: this.resetPassword
        });
        if (res.code == 200) {
          this.$router.push("/superLogin");
          success("修改成功");
        } else {
          // error(res.data.error_msg);
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
#forgetPassword {
  input:focus {
    transition: 0.2s;
    border-bottom: 1px solid #40587a;
  }
  ::-webkit-input-placeholder {
    color: #99a9bf;
  }
  .title {
    font-size: 30px;
    text-align: center;
    margin-top: 147px;
    margin-bottom: 60px;
  }
  .loginBox {
    background: white;
    box-shadow: 0 4px 4px 0 rgba(64, 88, 122, 0.1);
    border-radius: 2px;
    width: 416px;
    margin: 0 auto;
    padding: 64px;
    margin-bottom: 209px;
  }
  .backLogin {
    color: #20a0ff;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
  margin-top: 49px;
  input {
    outline: none;
    border: none;
    font-size: 13px;
    padding: 11px 0;
    border-bottom: 1px solid #e0e6ed;
    width: 100%;
    margin-top: 19px;
  }
  .codeFrame {
    position: relative;
    button {
      font-size: 13px;
      color: #40587a;
      position: absolute;
      right: 0;
      bottom: 3px;
    }
  }
  .loginBtn {
    margin-top: 56px;
    width: 100%;
    color: white;
    font-size: 16px;
    height: 46px;
    background: #40587a;
    border-radius: 4px;
  }
  .loginBtn:active {
    background: #2b405e;
  }
}
</style>
